import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Metatags from '../components/Metatags'

const NotFoundPage = () => (
  <Layout>
    <section className="trt-page-not-found">
      <div className="container">
        <div className="box">
          <div className="box__ghost">
            <div className="symbol"></div>
            <div className="symbol"></div>
            <div className="symbol"></div>
            <div className="symbol"></div>
            <div className="symbol"></div>
            <div className="symbol"></div>
            <div className="box__ghost-container">
              <div className="box__ghost-eyes">
                <div className="box__eye-left"></div>
                <div className="box__eye-right"></div>
              </div>
              <div className="box__ghost-bottom">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="box__ghost-shadow"></div>
          </div>
          <div className="box__description">
            <div className="box__description-container">
              <div className="box__description-title">Whoops!</div>
              <div className="box__description-text">It seems like we couldn't find the page you were looking for
                        </div>
            </div>
            <Link type="button"
              className="btn btn-trt-primary"
              to="/">Back To Home</Link>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default NotFoundPage